export enum IDE_TAB_POSITION {
  TOP = 'Top',
  RIGHT = 'Right',
  BOTTOM = 'Bottom'
}

export enum EXECUTE_BUTTON_POSITION {
  TOP = 'top',
  FLOATINGRIGHT = 'floatingright',
  FLOATINGBOTTOM = 'floatingbottom'
}

export const IDERELETIVEHEIGHT = {
  EXCLUDEID: 'relative-height-exclude-id',
  MINIMUMID: 'relative-height-minimum-id'
}

export const MOBILEIDENAVIGATIONIDS = {
  MENUDARKETLOGOID: 'menuDarkerLogoId',
  CODESEARCHBARID: 'mobileIdeCodeSearchBarId',
  IDENAVIGATION: 'mobileIdeNavigation',
  PROFILEPICID: 'profilePicId',
  PROFILETAB: 'mobileIdeProfileTab'
}

export const DESKTOPNAVIGATIONIDS = {
  SHOW_NAVIGATION_MENU_ID: 'SHOW_NAVIGATION_MENU_ID',
  SHOW_TAB_SETTINGS_ICON_IDE: 'SHOW_TAB_SETTINGS_ICON_IDE'
}

export const IDECONSTANT = {
  STOTAGE_KEY: 'JDoodleRecent',
  LOCALSTORAGE_FONT_SIZE_NAME: 'JDoodle-Font-Size',
  CODE_EDITOR: 'code',
  OUTPUT_EDITOR: 'output',
  AI_OUTPUT_EDITOR: 'ai-output',
  EXECUTE_CODE_EDITOR: 'myExecuteHistoryCodeEditor',
  EXECUTE_OUTPUT_EDITOR: 'myExecuteHistoryOutputEditor',
  COPY_CODE_EDITOR: 'myCopyCodeEditor',
  DOWNLOAD_CODE_EDITOR: 'myDownloadCodeEditor',
  OPEN_FROM_FILE_EDITOR: 'myOpenFromFileEditor',
  BLOCKLY_EDITOR: 'blocklyEditor',
  HTML_DOC_TYPE_EDITOR: 'html-doctype-code',
  HTML_HEAD_EDITOR: 'html-head-code',
  HTML_BODY_EDITOR: 'html-body-code',
  HTML_JS_EDITOR: 'js-code',
  HTML_CSS_EDITOR: 'css-code',
  TERMIANL_EDITOR: 'terminal',
  DASHBOARDCODEEDITOR: 'dashboard-code',
  IDEHOMEVIEW: 'ideHomeView',
  ADVCOMPILERTERMINAL: 'advcompilerterminal'
}

export const SERVER_ERROR =
  'Unable to process your request. Please try again, or contact JDoodle Support at hello@jdoodle.com.'
export const LIB_ERROR = 'External libraries are not supported for this language version.'
export const SYNC_ERROR =
  'Unable to Sync files with Server.Please try again or contact JDoodle Support'

export const SYNC_ACTIONS = {
  NEW_ITEM: 'new_item',
  RENAME: 'rename',
  FILE_CHANGED: 'file_changed',
  DELETE: 'delete',
  ITEM_MOVED: 'item_moved'
}

export const FILE_MESSAGE_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info'
}

export const ADVANCEDIDETYPE = {
  INIT: 'init',
  OPEN: 'open',
  CLEAR: 'clear',
  OPENSHARE: 'open-share'
}

export const CMD_LANGUAGES = [
  'sql',
  'brainfuck',
  'hack',
  'yabasic',
  'verilog',
  'whitespace',
  'nasm',
  'gccasm',
  'falcon',
  'factor',
  'spidermonkey',
  'rhino'
]
export const BLOCKLANGUAGELIST = [
  { language: 'Dart', isAceLanguageCode: 'dart' },
  { language: 'Lua', isAceLanguageCode: 'lua' },
  { language: 'PHP', isAceLanguageCode: 'php' },
  { language: 'Python', isAceLanguageCode: 'python' }
]
export const BLOCKLIST = {
  categories: [
    'Logic',
    'Controls',
    'Lists',
    'Loops',
    'Color',
    'Math',
    'Texts',
    'Variables',
    'Variables Dynamic',
    'Procedures'
  ],
  blockTypes: [
    [
      'logic_boolean',
      'logic_compare',
      'logic_operation',
      'logic_negate',
      'logic_null',
      'logic_ternary'
    ],
    [
      'controls_if',
      'controls_ifelse',
      'controls_if_if',
      'controls_if_elseif',
      'controls_if_else',
      'logic_ternary'
    ],
    [
      'lists_repeat',
      'lists_reverse',
      'lists_isEmpty',
      'lists_length',
      'lists_create_with',
      'lists_create_with_container',
      'lists_create_with_item',
      'lists_indexOf',
      'lists_getIndex',
      'lists_setIndex',
      'lists_getSublist',
      'lists_sort',
      'lists_split'
    ],
    [
      'controls_repeat_ext',
      'controls_repeat',
      'controls_whileUntil',
      'controls_for',
      'controls_forEach',
      'controls_flow_statements'
    ],
    ['colour_picker', 'colour_random', 'colour_rgb', 'colour_blend'],
    [
      'math_number',
      'math_arithmetic',
      'math_single',
      'math_trig',
      'math_constant',
      'math_number_property',
      'math_change',
      'math_round',
      'math_on_list',
      'math_modulo',
      'math_constrain',
      'math_random_int',
      'math_random_float',
      'math_atan2'
    ],
    [
      'text_print',
      'text',
      'text_multiline',
      'text_join',
      'text_create_join_container',
      'text_create_join_item',
      'text_append',
      'text_length',
      'text_isEmpty',
      'text_indexOf',
      'text_charAt',
      'text_getSubstring',
      'text_changeCase',
      'text_trim',
      'text_prompt_ext',
      'text_prompt',
      'text_count',
      'text_replace',
      'text_reverse'
    ],
    ['variables_get', 'variables_set'],
    ['variables_get_dynamic', 'variables_set_dynamic'],
    [
      'procedures_defnoreturn',
      'procedures_defreturn',
      'procedures_mutatorcontainer',
      'procedures_mutatorarg',
      'procedures_callnoreturn',
      'procedures_callreturn',
      'procedures_ifreturn'
    ]
  ]
}

export const DEFAULTDBTAB = 'NewQuery'
